<template>
  <m-basic-form @save="save" @remove="remove" :loadingMsg="loadingMsg" :loadingShow="loadingShow" :formId="ID">
    <div class="row">
      <div class="col-6">
        <m-input v-model="form.NAME" langlabel="name"></m-input>
        <m-textarea v-model="form.TOKEN" langlabel="token" readonly />
        <b-btn size="sm" style="float:right" variant="info" @click="createToken">Tokeni yeniden oluştur</b-btn>
        <div class="clear:both"></div>
        <m-yes-no v-model="form.ISACTIVE" langlabel="isactive" />

        <m-checkbox v-model="form.SELECTEDPERMS" label="İzin verilen entegrasyonlar" :options="[{
          text: 'Gönderici Birim Entegrasyonu', value: 'ISREST',
        }, {
          text: 'Muhasebe Birim Entegrasyonu', value: 'ISACCOUNTREST',
        }, {
          text: 'Dağıtıcı Birim Entegrasyonu', value: 'ISCLIENTACCOUNT',
        }, {
          text: 'Kargo Takip Servisi Entegrasyonu', value: 'ISONLYCARGOSTATUS',
        }]" />

        <div v-show="isClient">
          <h6 class="text-center">Birim Bilgileri</h6>
          <m-input v-model="form.CLIENTCODE" langlabel="clientcode" />
          <m-input v-model="form.CLIENTNAME" langlabel="clientname" />
          <m-select label="Birim Şubesini Seçiniz" v-model="form.RESELLERID" :options="options.resellers"></m-select>
        </div>
      </div>

      <div class="col-6" v-if="isSender">
        <div class="row">
          <div class="col-4 text-right">
            {{ $t("form.presentsearchorselect") }}
          </div>
          <div class="col-8">
            <multiselect v-model="selected.present" :options="lookup.presentData"
              :placeholder="$t('form.presentsearchorselect')" label="text" track-by="text" :limit="3"
              @search-change="searchPresent" :selectLabel="$t('multiselect.pressentertoselect')"
              :selectedLabel="$t('multiselect.selected')" :deselectLabel="$t('multiselect.pressentertoselect')">
              <span slot="noResult">{{ $t("multiselect.nodata") }}</span>
              <span slot="noOptions">{{ $t("multiselect.nodata") }}</span>
            </multiselect>
            <br />
          </div>
        </div>
        <m-input readonly :value="selected.present ? selected.present.FIRMNAME : null" langlabel="firmname" />
        <m-input readonly :value="selected.present ? selected.present.FULLNAME : null" langlabel="fullname" />
        <m-input readonly :value="selected.present ? selected.present.TAXOFFICE : null" langlabel="taxoffice" />
        <m-input readonly :value="selected.present ? selected.present.IDENTITYNO : null" langlabel="taxno" />
        <m-select :options="options.address" v-model="form.SENDERADDRESSID" langlabel="address" />
      </div>

      <div class="col-12">
        <h5>Önemli Bilgilendirmeler</h5>
        <ul class="list-group list-group-flush">
          <li class="list-group-item" style="background-color:transparent !important;">
            <strong>1) </strong> Token otomatik oluşmaktadır. Standart olarak 255 karakter olmaldır. Lütfen Tokeni
            entegrasyon yapacağınız ilgili firma hariç kimse ile paylaşmayın.
          </li>
          <li class="list-group-item" style="background-color:transparent !important;"><strong>2) </strong> Size kargo
            gönderecek olan firmalar için sadece <i>Gönderici Birim Entegrasyonu</i> izni veriniz.</li>
          <li class="list-group-item" style="background-color:transparent !important;"><strong>3) </strong> Sizin
            kargonuzu gönderecek alt firmalar için sadece <i>Dağıtıcı Birim Entegrasyonu</i> izni veriniz.</li>
          <li class="list-group-item" style="background-color:transparent !important;"><strong>4) </strong> Kargo
            firmanız ile kendi muhasebe entegrasyonu yapmanız için sadece <i>Muhasebe Birim Entegrasyonu</i> izni
            veriniz.</li>
          <li class="list-group-item" style="background-color:transparent !important;"><strong>5) </strong> Kendi
            kurumsal sitenizde kargo takip bilgilerinizi paylşamak için sadece <i>Kargo Takip Servisi Entegrasyonu</i>
            izni
            veriniz.</li>
          <li class="list-group-item" style="background-color:transparent !important;"><strong>6) </strong>Token
            güvenliğini sağlamak ve bu sayfadaki bilgileri doğru girmek tamamen sizin sorumluluğunuzdadır. </li>

          <li class="list-group-item" style="background-color:transparent !important;"><strong>7) </strong>Gönderici
            Birim Entegrasyonu yapmak için Müşteri ve Adresinin daha önceden sisteme kayıt edilmesi gerekmektedir.</li>

          <li class="list-group-item" style="background-color:transparent !important;"><strong>8) </strong>Dağıtıcı
            Birim Entegrasyonu için ilgili birime kargoların aktarılabilmesi için daha önceden şube olarak tanımlaması
            ve ilgili tüm bilgilerinin girilmesi gerekmektedir.</li>

          <li class="list-group-item" style="background-color:transparent !important;"><strong>9) </strong>Burdaki
            işlemleri tam olarak bilmeden ve belirlemeden kayıt oluşturmamanızı öneririz.</li>
          <li class="list-group-item" style="background-color:transparent !important;"><strong>10) </strong><span
              class="text-danger">Yanlış yaptığınız tüm işlemlerin sistemde sorunlara yol açabileceğini ve sorumluluğun
              tamamının sizde olduğunu unutmayın!</span></li>
        </ul>
      </div>
    </div>
  </m-basic-form>
</template>

<script>
import api from "../../api";
import swal from "sweetalert";
export default {
  mounted() {
    this.getResellers()
    if (intval(this.$route.params.id) > 0) {
      this.ID = intval(this.$route.params.id);
      this.getApiToken(this.ID);
    } else {
      this.createToken();
    }
  },
  data() {
    return {
      ID: null,
      form: {
        NAME: null,
        TOKEN: null,
        ISREST: '0',
        ISONLYCARGOSTATUS: '0',
        SENDERID: null,
        SENDERADDRESSID: null,
        CLIENTID: null,
        ISCLIENTACCOUNT: '0',
        ISACCOUNTREST: '0',
        ISACTIVE: 1,
        SELECTEDPERMS: [],

        CLIENTCODE: null,
        CLIENTNAME: null,
        CLIENTID: null,

        RESELLERID: null,
      },
      loadingShow: false,
      loadingMsg: null,

      selected: {
        present: null,
      },
      lookup: {
        presentData: [],
      },
      options: {
        address: [],
        resellers: [],
      },
    };
  },
  computed: {
    isClient() {
      if (this.form.SELECTEDPERMS.indexOf('ISCLIENTACCOUNT') > -1) {
        return true;
      }
      return false;
    },
    isSender() {
      if (this.form.SELECTEDPERMS.indexOf('ISREST') > -1) {
        return true;
      }
      return false;
    },
  },
  methods: {
    createToken() {
      this.form.TOKEN = this.makeid(255);

    },
    getResellers() {
      api.getReseller().then((res) => {
        this.options.resellers = res.data.data.rows.map((item) => {
          return {
            text: item.RESELLERNAME,
            value: item.ID,
          };
        });


      });
    },
    getAddress(id) {
      api.getPresentAddressWithPresentId(id).then((res) => {
        if (res.data.status) {
          this.options.address = res.data.data.rows.map((item) => {
            return {
              text: item.ADDRESSNAME,
              value: item.ID,
            };
          });
        }
      });
    },
    searchPresent(search) {
      if (search.length > 2) {
        this.loadingShow = true;
        this.loadingMsg = this.$t("form.loading");
        api.getPresentWithTerm(search).then((res) => {
          this.lookup.presentData = res.data.data.map((item) => {
            return {
              ...item,
              text: `${item.FIRMNAME ? item.FIRMNAME : ""} (${item.FULLNAME ? item.FULLNAME : ""
                }) [${item.IDENTITYNO ? item.IDENTITYNO : ""}]`,
              value: item.ID,
            };
          });
          this.loadingShow = false;
        });
      }
    },
    makeid(length) {
      let result = '';
      let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
          charactersLength));
      }
      return result;
    },
    save() {

      if (!this.form.NAME) {
        swal({
          title: this.$t("dialog.notice"),
          text: this.$t("dialog.required_name"),
          icon: "warning",
        });
        return;
      }

      if (this.isSender) {


        if (this.selected.present) {
          this.form.SENDERID = this.selected.present.ID;
        } else {

          swal({
            title: this.$t("dialog.notice"),
            text: this.$t("dialog.senderwarning"),
            icon: "warning",
          })
          return;

        }

        if (!this.form.SENDERID) {
          swal({
            title: this.$t("dialog.notice"),
            text: this.$t("dialog.senderidwarning"),
            icon: "warning",
          })
          return;
        }

        if (!this.form.SENDERADDRESSID) {
          swal({
            title: this.$t("dialog.notice"),
            text: this.$t("dialog.senderaddressidwarning"),
            icon: "warning",
          })
          return;
        }

      }

      if (!this.form.TOKEN) {
        swal({
          title: this.$t("dialog.notice"),
          text: this.$t("dialog.tokenwarning"),
          icon: "warning",
        })
        return;
      }

      if (this.form.TOKEN.length != 255) {
        swal({
          title: this.$t("dialog.notice"),
          text: this.$t("dialog.tokenlengthwarning"),
          icon: "warning",
        })
        return;
      }

      if (this.form.SELECTEDPERMS.length == 0) {
        swal({
          title: this.$t("dialog.notice"),
          text: this.$t("dialog.permswarning"),
          icon: "warning",
        })
        return;
      }

      if (this.isClient) {
        if (!this.form.CLIENTCODE) {
          swal({
            title: this.$t("dialog.notice"),
            text: this.$t("dialog.clientcodewarning"),
            icon: "warning",
          })
          return;
        }
        if (!this.form.CLIENTNAME) {
          swal({
            title: this.$t("dialog.notice"),
            text: this.$t("dialog.clientnamewarning"),
            icon: "warning",
          })
          return;
        }
        if (!this.form.RESELLERID) {
          swal({
            title: this.$t("dialog.notice"),
            text: this.$t("dialog.reselleridwarning"),
            icon: "warning",
          })
          return;
        }
      }



      this.loadingMsg = this.$t("dialog.savingrecord");
      this.loadingShow = true;
      api
        .saveApiToken(
          {
            ...this.form,
          },

          this.ID
        )
        .then((res) => {
          if (res.data.status) {
            if (this.ID == res.data.data.ID) {
              swal(
                this.$t("dialog.success"),
                this.$t("dialog.successfullyupdatedrecord"),
                "success"
              );
            } else {
              this.ID = res.data.data.ID;
              swal(
                this.$t("dialog.success"),
                this.$t("dialog.successfullysaverecord"),
                "success"
              );
            }
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    getApiToken(id) {
      this.loadingMsg = this.$t("dialog.fetchingdata");
      this.loadingShow = true;
      api
        .getApiTokenOne(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            const {
              NAME,
              TOKEN,
              ISREST,
              ISONLYCARGOSTATUS,
              SENDERID,
              SENDERADDRESSID,
              CLIENTID,
              ISCLIENTACCOUNT,
              ISACCOUNTREST,
              ISACTIVE,
              CLIENTCODE,
              CLIENTNAME,
              RESELLERID,
              FULLNAME,
              FIRMNAME
            } = res.data.data;
            let selectedPerms = [];
            if (ISREST == '1') {
              selectedPerms.push('ISREST');
            }
            if (ISONLYCARGOSTATUS == '1') {
              selectedPerms.push('ISONLYCARGOSTATUS');
            }
            if (ISACCOUNTREST == '1') {
              selectedPerms.push('ISACCOUNTREST');
            }
            if (ISCLIENTACCOUNT == '1') {
              selectedPerms.push('ISCLIENTACCOUNT');
            }
            this.form = {
              NAME,
              TOKEN,
              ISREST,
              ISONLYCARGOSTATUS,
              SENDERID,
              SENDERADDRESSID,
              CLIENTID,
              ISCLIENTACCOUNT,
              ISACCOUNTREST,
              ISACTIVE,

              SELECTEDPERMS: selectedPerms,

              CLIENTCODE,
              CLIENTNAME,
              CLIENTID,

              RESELLERID,
            };
            this.lookup.presentData = [{
              text: `${FULLNAME} (${FIRMNAME})`,
              value: SENDERID
            }];
            this.selected.present = {
              text: `${FULLNAME} (${FIRMNAME})`,
              value: SENDERID
            };

          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    remove() {
      this.loadingMsg = this.$t("dialog.removingrecord");
      this.loadingShow = true;
      api
        .deleteApiToken(this.ID)
        .then((res) => {
          if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
  },
  watch: {
    "selected.present": function (val) {
      if (val.value) {
        this.getAddress(val.value);
      }
    }
  }
};
</script>

<style>
</style>
